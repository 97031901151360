import React, { useState } from "react";
import { ButtonStyle } from "../js/enums";
import { handleRedirect } from "../components/form/Helpers";
import { useGlobalReducer } from "../GlobalContext";
import { useFormReducer } from "../components/form/FormContext";
import { Update } from "../js/service";
import Button from "../components/elements/Button";

function ReprocessTelegramQueue({ state, values }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();
  const reprocess = async () => {
    setIsSubmitting(true);
    try {
      const successMessage = "Record Reprocessed";
      const [serviceResponse] = await Promise.all([
        Update(
          globalDispatch,
          successMessage,
          {
            Id: state.id,
            Name: state.g4b_name,
            Attributes: {g4b_stage : 1},
          },
          "g4b_telegramqueue"
        ),
      ]);
      if (serviceResponse && serviceResponse.data) {
        handleRedirect(null, dispatch, state.id, "refreshPage");
        return;
      }
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  return (
        <>
          <Button
            className="text-decoration-none"
            style={ButtonStyle.Primary}
            text={"Reprocess"}
            onClick={() => reprocess()}
            showSpinner={isSubmitting}
            disabled={isSubmitting}
          />
        </>
  );
}

export default ReprocessTelegramQueue;
