import { administratorRoles } from "../js/roles";
import {
  checkDateTimeIsAfterToday,
  createDateFromToday,
  isSameOrLaterDate,
} from "../js/utility";
import lookups from "./lookups";
import { OnHoldReasonFormStages } from "./onHoldReason";

export const OnHoldAuditFormStages = [
  {
    title: "On Hold Audit",
    statusEditAllowedRoles: [],
    recordEditRoles: administratorRoles,
    sections: [
      {
        name: "",
        //g4b_onhold_contactid should be auto filled in when adding or editing a record when coming from contact page
        //If not auto filled in then do not display anything on the page
        displayIfFromValues: (values) => {
          return values && values.g4b_onhold_contactid;
        },
        fields: [
          {
            name: "g4b_onhold_contactid",
            hidden: true,
          },
          {
            name: "g4b_onhold_contactidname",
            className: "col-md-6",
            description: "",
            label: "On Hold Contact",
            disabled: true,
            displayIfFromState: (state) => state.id,
            type: "nvarchar",
          },
          {
            name: "createdon",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy HH:mm",
            displayIfFromState: (state) => state.id,
            label: "On Hold Date",
            disabled: true,
            type: "datetime",
          },
          {
            name: "g4b_onholdreasonid",
            allowAdd: true,
            allowEdit: true,
            className: "col-md-6",
            description: "",
            disabledIfFromState: (state) => state.id,
            label: "On Hold Reason",
            lookup: lookups.g4b_onholdreason.all,
            required: true,
            subFormStages: OnHoldReasonFormStages,
            type: "lookup",
          },
          {
            name: "g4b_additional_onhold_information",
            className: "col-md-6",
            description: "",
            disabledIfFromState: (state) => state.id,
            label: "Additional On Hold Info",
            type: "nvarchar",
          },
          {
            name: "g4b_datetimeoffhold",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy",
            description: "",
            label: "Off Hold Date",
            disabledIfFromState: (state) =>
              state.g4b_datetimeoffhold &&
              !checkDateTimeIsAfterToday(state.g4b_datetimeoffhold),
            filterDate: (date) => {
              return isSameOrLaterDate(
                date,
                createDateFromToday(-1, 0, 0)
              );
            },
            type: "datetime",
          },
          {
            name: "g4b_additional_offhold_information",
            className: "col-md-6",
            description: "",
            disabledIfFromState: (state) => {
              return (
                state.g4b_datetimeoffhold &&
                !checkDateTimeIsAfterToday(state.g4b_datetimeoffhold)
              );
            },
            label: "Additional Off Hold Info",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];
