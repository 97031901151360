export const OnHoldReasonFormStages = [
  {
    title: "On Hold Reason",
    id: "on-hold-reason",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            className: "col-md-8",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_reasoncode",
            className: "col-md-4",
            description: "",
            label: "Reason Code",
            type: "nvarchar",
          },
          {
            name: "g4b_allowaccess",
            description: "",
            label: "Allow Access",
            type: "bit",
          },
          {
            name: "g4b_allowpurchase",
            description: "",
            label: "Allow Purchase",
            type: "bit",
          },
          {
            name: "g4b_allowticketallocation",
            description: "",
            label: "Allow Ticket Allocation",
            type: "bit",
          },
        ],
      },
    ],
  },
];
